import axios from "axios";
import { IOrderFilter } from "../models/Order/OrderFilter";
import qs from "qs";
import { DateHelper } from "helpers/DateHelper";
import { IObjectStatus, IOrder, IOrderResponse } from "models/Order/Order";
import { Endpoints } from "config/endpoints";

interface IOrderService {
  getOrders(params: Partial<IOrderFilter>): Promise<IOrderResponse>;

  getOrder(orderId: string): Promise<IOrder>;

  getOrderStatuses(): Promise<IObjectStatus[]>;

  getPDF(fileName: string): Promise<string>;

  getMultiplePDF(pdfNames: string): Promise<{ id: string; fileData: string }[]>;
}

const orderServiceUrl = Endpoints.getEndpoint("REACT_APP_ORDER_ENDPOINT");

export const OrdersService: IOrderService = {
  getOrders: async (params: Partial<IOrderFilter>): Promise<IOrderResponse> => {
    const url = `${orderServiceUrl}orders/headers`;
    const { data } = await axios.get(url, {
      params,
      paramsSerializer: (params) =>
        qs.stringify(params, {
          serializeDate: (date: Date) =>
            DateHelper.format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS"),
        }),
    });
    return Promise.resolve(data);
  },

  getOrder: async (orderId: string): Promise<IOrder> => {
    const url = `${orderServiceUrl}orders/${orderId}`;
    const { data } = await axios.get(url);

    return Promise.resolve(data);
  },

  getOrderStatuses: async (): Promise<IObjectStatus[]> => {
    const url = `${orderServiceUrl}statuses`;
    const { data } = await axios.get(url);
    return Promise.resolve(data);
  },

  getPDF: async (pdfName: string): Promise<string> => {
    const url = `${orderServiceUrl}order/getpdf/${pdfName}`;
    const { data } = await axios.get(url);
    return Promise.resolve(data);
  },

  getMultiplePDF: async (
    pdfNames: string
  ): Promise<{ id: string; fileData: string }[]> => {
    const url = `${orderServiceUrl}order/getMultiplePDF/${pdfNames}`;
    const { data } = await axios.get(url);
    return Promise.resolve(data);
  },
} as IOrderService;
